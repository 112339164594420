<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="heading_alignment">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="organisationAdd">
              {{cvbtnAddNew}}
            </b-button>
          </template>
          <template>
            <b-row>
              <b-col class="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col class="col-12 col-sm-5 col-md-3 col-lg-3 mb-3">
              <select
                v-model="cvOrgType"
                type="text"
                class="form-control"
                id="validation_degree"
                @change=organisationListAdmin()
                >
                <option v-for="(OrgType, index) of OrgType" :value="OrgType" :key="index+'OrgType'">{{ index }}</option>
              </select>
              </b-col>
              <b-col class="col-8 col-sm-4 col-md-3 col-lg-3 mb-3">
                <CsvDownload :propModuleFile="cvModuleFile" class="btn mr-1 Download_button" />
                <CsvUpload :propModulePrefix="cvModulePrefix" class="btn Download_button"  />
              </b-col>
              <b-col class="w-100 col-4 col-sm-3 col-md-2 col-lg-2 mb-3" v-if="organisationObjList && organisationObjList.length">
                <VueJsonToCsv :json-data="organisationObjList" class="pull-right" csv-title="OrganisationList" >
                  <b-btn class="Download_button" variant="primary">csv
                  </b-btn>
                </VueJsonToCsv>
              </b-col>
              <!-- <b-col md="8">
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Excel</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Csv</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Coustomize</b-button>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col md="12" v-if="organisationObjList && organisationObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="organisationObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(deleted)="data">
                    <b-badge v-if="data.item.deleted === 0" class="pointer ml-1" variant="success mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                      Published
                    </b-badge>
                    <b-badge v-if="data.item.deleted === 2" class="pointer ml-1" variant="warning mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                      Unpublished
                    </b-badge>
                    <b-badge v-if="data.item.deleted === 1" class="pointer ml-1" variant="primary mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                      Deleted
                    </b-badge>

                    <b-button variant="light mr-1 mb-1" :disabled="data.item.deleted === 2" size="sm" @click="organisationPublishUnpublish(data.item)" v-if="!data.item.editable">
                      <i class="fa fa-toggle-off m-0" title="Unpublish" style="color:red"></i>
                    </b-button>
                    <b-button variant="light mr-1 mb-1" :disabled="data.item.deleted === 0" size="sm" @click="organisationPublishUnpublish(data.item)" v-if="!data.item.editable">
                      <i class="fa fa-toggle-on m-0" title="Publish" style="color:green"></i>
                    </b-button>
                  </template>

                  <template v-slot:cell(org_name)="data">
                    {{data.item.org_name}}
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="organisationEdit('MODAL', data.item)" v-if="!data.item.editable">
                      <i class="ri-ball-pen-fill m-0"></i>
                    </b-button>
                    <b-button variant=" iq-bg-danger" @click="showOrganisationDeleteDialog(data.item)" size="sm">
                      <i class="ri-delete-bin-line m-0"></i>
                    </b-button>
                  </template>
                  <template v-slot:cell(org_id)="data">
                    <p v-html="data.item.org_id" style="word-wrap: break-word" >
                    </p>
                  </template>
                  <template v-slot:cell(org_desc)="data">
                    <p v-html="data.item.org_desc" class="threeLineOnly vhtmlTag vueHtmlParent">
                    </p>
                  </template>
                  <template v-slot:cell(org_admission_requirement)="data">
                    <p v-html="data.item.org_admission_requirement" class="threeLineOnly vhtmlTag vueHtmlParent">
                    </p>
                  </template>
                  <template v-slot:cell(org_top_alums)="data">
                    <p v-html="data.item.org_top_alums" class="threeLineOnly vhtmlTag vueHtmlParent">
                    </p>
                  </template>
                  <template v-slot:cell(org_clubs)="data">
                    <p v-html="data.item.org_clubs" class="threeLineOnly vhtmlTag vueHtmlParent">
                    </p>
                  </template>
                  <template v-slot:cell(org_major_industries_around)="data">
                    <p v-html="data.item.org_major_industries_around" class="threeLineOnly vhtmlTag vueHtmlParent">
                    </p>
                  </template>
                  <template v-slot:cell(org_priority)="data">
                    <p v-html="data.item.org_priority" class="threeLineOnly vhtmlTag vueHtmlParent">
                    </p>
                  </template>
                  <template v-slot:cell(org_tnc)="data">
                    {{data.item.org_tnc === 0 ? "0" : "1"}}
                    <b-button variant=" iq-bg-success mr-1 mb-1" :disabled="data.item.org_tnc === 1" size="sm" @click="organisationEditTnc(data.item)" v-if="!data.item.editable">
                      <i class="fa fa-toggle-on m-0" title="tnc0"></i>
                    </b-button>
                    <b-button variant=" iq-bg-success mr-1 mb-1" :disabled="data.item.org_tnc === 0" size="sm" @click="organisationEditTnc(data.item)" v-if="!data.item.editable">
                      <i class="fa fa-toggle-off m-0" title="tnc1" style="color:red"></i>
                    </b-button>
                  </template>
                  <template v-slot:cell(org_deadline)="data">
                    {{getFormattedDate(data.item.org_deadline)}}
                  </template>
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                  <!--
                    <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="organisationEdit('MODAL', data.item)" v-if="!data.item.editable">
                      <i class="ri-ball-pen-fill m-0"></i>
                    </b-button>
                    <b-button variant=" iq-bg-danger" @click="showOrganisationDeleteDialog(data.item)" size="sm">
                      <i class="ri-delete-bin-line m-0"></i>
                    </b-button>
                  </template>
                -->
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal v-model="showModelOrganisationDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelOrganisationDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="organisationDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<script>
import { Organisations } from "../../../FackApi/api/organisation.js"
import CsvUpload from "../../../components/Csv/CsvUpload.vue"
import CsvDownload from "../../../components/Csv/CsvDownload.vue"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import OrgType from "../../../FackApi/json/OrgType.json"

export default {
  name: "OrganisationList",
  components: {
    CsvDownload,
    CsvUpload
  },
  data () {
    return {
      cvModuleFile: "organisation",
      cvCardTitle: "Organisation List",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Organisation",
      cvAddModalHeader: "Add Organisation",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Organisation List Response",
      showModelOrganisationAdd: false,
      showModelOrganisationEdit: false,
      showModelOrganisationDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Status ", key: "deleted", class: "text-left align-text-top", sortable: true },
        { label: "Univ Id", key: "org_id", class: "text-left align-text-top pointer", sortable: true },
        { label: "Name", key: "org_name", class: "text-left align-text-top", sortable: true },
        { label: "Description", key: "org_desc", class: "text-left align-text-top limitedCharDesc", sortable: true },
        { label: "Email", key: "org_admin_email", class: "text-left align-text-top", sortableC: true },
        { label: "Mobile", key: "org_mobile", class: "text-left align-text-top", sortable: true },
        { label: "City", key: "org_city", class: "text-left align-text-top", sortable: true },
        { label: "Country", key: "org_country", class: "text-left align-text-top", sortable: true },
        { label: "tnc", key: "org_tnc", class: "text-left align-text-top", sortable: true },
        { label: "Priority", key: "org_priority", class: "text-left align-text-top", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true }
      ],
      organisationObjList: [],
      organisationEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null,
      cvModulePrefix: "ORG",
      OrgType: OrgType,
      cvOrgType: "UNIV"
    }
  },
  mounted () {
    this.organisationListAdmin()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDate (date) {
      try {
        return moment(date).format("DD-MM-YYYY")
      }
      catch (err) {
        console.error("Exception occurred at getFormattedDate() and Exception:", err.message)
      }
    },
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.error("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * organisationListAdmin
     */
    async organisationListAdmin () {
      const filter = {
        type: [`${this.cvOrgType}`]
      }

      try {
        let organisationListResp = await Organisations.organisationListAdmin(this, filter)
        if (organisationListResp.resp_status) {
          this.organisationObjList = Object.values(organisationListResp.resp_data.data)
          this.totalRows = organisationListResp.resp_data.count
        }
        else {
          this.toastMsg = organisationListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationListAdmin() and Exception:", err.message)
      }
    },
    /**
     * organisationAdd
     */
    organisationAdd () {
      try {
        this.$router.push("/admin/organisation_add")
      }
      catch (err) {
        console.error("Exception occurred at organisationAdd() and Exception:", err.message)
      }
    },
    /**
     * organisationEdit
     */
    organisationEdit (type, item) {
      try {
        this.organisationEditObj = item
        this.$router.push("/organisation_edit/" + item.type + "/" + item.org_id)
      }
      catch (err) {
        console.error("Exception occurred at organisationEdit() and Exception:", err.message)
      }
    },
    /**
     * showOrganisationDeleteDialog
     */
    showOrganisationDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelOrganisationDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showOrganisationDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * organisationDelete
     */
    async organisationDelete () {
      try {
        let organisationDelResp = await Organisations.organisationDelete(this, this.delObj.org_id)
        await ApiResponse.responseMessageDisplay(this, organisationDelResp)

        if (organisationDelResp && !organisationDelResp) {
          this.showModelOrganisationDelete = false
          return false
        }

        let index = this.organisationObjList.indexOf(this.delObj)
        this.organisationObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelOrganisationDelete = false
      }
      catch (err) {
        console.error("Exception occurred at organisationDelete() and Exception:", err.message)
      }
    },
    /**
     * closeOrganisationAddModal
     */
    closeOrganisationAddModal (organisationAddData) {
      try {
        if (organisationAddData) {
          this.showModelOrganisationAdd = false
          if (this.organisationObjList && this.organisationObjList.length >= 1) {
            let orgObjLength = this.organisationObjList.length
            let lastId = this.organisationObjList[orgObjLength - 1]["id"]
            organisationAddData.id = lastId + 1
          }
          else {
            this.organisationObjList = []
            organisationAddData.id = 11111
          }

          organisationAddData.created_on = moment()
          this.organisationObjList.unshift(organisationAddData)
          this.totalRows = this.totalRows + 1
        }
        else {
          this.showModelOrganisationAdd = false
        }
      }
      catch (err) {
        console.error("Exception occurred at closeorganisationAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeOrganisationEditModal
     */
    closeOrganisationEditModal () {
      try {
        this.showModelOrganisationEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeOrganisationEditModal() and Exception:", err.message)
      }
    },
    /**
     * Publish/UnPublish Organisation
     */
    async organisationPublishUnpublish (org) {
      let payload = {
        org_id: org.org_id,
        deleted: null
      }

      if (org.deleted === 0) {
        // org is published then unpublish
        payload.deleted = 2
      }
      else if (org.deleted === 2) {
        payload.deleted = 0
      }
      else {
        return
      }

      let orgResp = await Organisations.organisation_publish_unpublish(this, payload)
      if (orgResp.resp_status === true) {
        org.deleted = payload.deleted
      }
      else {
        ApiResponse.responseMessageDisplay(this, orgResp)
      }
    },
    /**
     * organisation Edit Tnc Update 0 to 1 and 1 to 0
     */
    async organisationEditTnc (org) {
      let payload = {
        org_id: org.org_id,
        org_tnc: null
      }

      if (org.org_tnc === 0) {
        // if org_tnc is 0 then make org_tnc to 1
        payload.org_tnc = 1
      }
      else if (org.org_tnc === 1) {
        payload.org_tnc = 0
      }
      else {
        return
      }

      let orgResp = await Organisations.organisationEditTnc(this, payload)
      if (orgResp.resp_status === true) {
        org.org_tnc = payload.org_tnc
      }
      else {
        ApiResponse.responseMessageDisplay(this, orgResp)
      }
    }
  }
}
</script>
